import { AppState } from "../app.state";

export const getMenuSettings =
  ({ key }) =>
  (state: AppState) =>
    state?.Config?.[key]?.kioskUISettings?.menu;

export const getLandingPage =
  ({ key }) =>
  (state: AppState) =>
    state?.Config?.[key]?.kioskUISettings?.landingPage;

export const getAvailableLanguage =
  ({ key }) =>
  (state: AppState) =>
    state?.Config?.[key]?.language?.systemLanguages;

export const getDefaulLanguage =
  ({ key }) =>
  (state: AppState) =>
    state?.Config?.[key]?.language?.defaultLanguage;

export const getLanguageStatus =
  ({ key }) =>
  (state: AppState) =>
    state?.Config?.[key]?.isRegionalLanguageSelected;

export const multiLanguageStatus =
  ({ key }) =>
  (state: AppState) =>
    state?.Config?.[key]?.isMultiLanguageEnabled;

export const getEnvironment =
  ({ key }) =>
  (state: AppState) =>
    state?.Config?.[key]?.kioskUISettings?.environment;

export const qrTimer =
  ({ key }) =>
  (state: AppState) =>
    state?.Config?.[key]?.kioskUISettings?.timer;

export const resetSessionTimer =
  ({ key }) =>
  (state: AppState) =>
    state?.Config?.[key]?.currentSessionTimer;

export const dinningEditable =
  ({ key }) =>
  (state: AppState) =>
    state?.Config?.[key]?.dineStatusSwitchingEnabled;

export const getMaxAllowedQuantity =
  ({ key }) =>
  (state: AppState) =>
    state?.Config?.[key]?.kioskUISettings?.miscellaneous?.maxQuantityAllowed;

export const selectPaymentModes =
  ({ key }) =>
  (state: AppState) =>
    state.Config?.[key]?.payment?.kioskFront;

export const getPaymentGateway =
  ({ key }) =>
  (state: AppState) =>
    state.Config?.[key]?.payment?.gateway;

export const themeEditable =
  ({ key }) =>
  (state: AppState) =>
    state?.Config?.[key]?.kioskUISettings?.theme?.isEditable;

export const getThemePalatte =
  ({ key }) =>
  (state: AppState) =>
    state?.Config?.[key]?.kioskUISettings?.theme?.colors;

export const defaultDiningSet =
  ({ key }) =>
  (state: AppState) =>
    state?.Config?.[key]?.defaultSet;

export const getAvailableDiningLabel =
  ({ key }) =>
  (state: AppState) =>
    state?.Config?.[key]?.availableDining;

export const getCurrentTheme =
  ({ key }) =>
  (state: AppState) =>
    state?.Config?.[key]?.currentTheme;

export const getWallpaperSetting =
  ({ key }) =>
  (state: AppState) =>
    state?.Config?.[key]?.kioskUISettings?.miscellaneous?.wallpaper;

export const showCookingInstruction =
  ({ key }) =>
  (state: AppState) =>
    state?.Config?.[key]?.kioskUISettings?.miscellaneous?.showInstructions;

export const isCartPreOpen =
  ({ key }) =>
  (state: AppState) =>
    state?.Config?.[key]?.kioskUISettings?.miscellaneous?.autoCartOpen;

export const showToast = (state: AppState) =>
  state?.Config?.[sessionStorage.getItem("location")]?.blockUiToast;

export const pollingStatus = (state: AppState) => {
  const storeId = sessionStorage.getItem("location");
  if (!state?.Config[storeId]?.isPrinterPollingEnabled) {
    return state?.Config[storeId]?.isPrinterPollingEnabled;
  }
};

export const autoPrintToken = (state: AppState) =>
  state?.Config?.[sessionStorage.getItem("location")]?.autoPrint?.token;

export const autoPrintInvoice = (state: AppState) =>
  state?.Config?.[sessionStorage.getItem("location")]?.autoPrint?.invoice;

export const stopSlotTimer = (state: AppState) =>
  state?.Config?.[sessionStorage.getItem("location")]?.stopSlotTimer;

export const printPreview =
  ({ key }) =>
  (state: AppState) =>
    state?.Config?.[key]?.printPreviewUrl;

export const getUpdatedDataTrack = (state: AppState) =>
  state?.Config?.[sessionStorage.getItem("location")]?.updatedDataTrack;

export const getConfigData = (state: AppState) =>
  state?.Config?.[sessionStorage.getItem("location")];

export const isSlotEnabled = (state: AppState) =>
  state.Config?.[sessionStorage.getItem("location")]?.slots?.enabled;

export const getDiningStatus =
  ({ key }) =>
  (state: AppState) =>
    state?.Config?.[key]?.isDining;

export const isInventoryEnabled = (state: AppState) =>
  state?.Config?.[sessionStorage.getItem("location")]?.inventoryManagement
    ?.stockCheck;

export const discardOrderEnabled =
  ({ key }) =>
  (state: AppState) =>
    state?.Config?.[key]?.kioskUISettings?.miscellaneous
      ?.discardOrderOnCancellingPayment;

export const isRatingSubmitted =
  ({ key }) =>
  (state: AppState) =>
    state?.Config?.[key]?.kioskUISettings?.miscellaneous;

export const isPopularEnabled =
  ({ key }) =>
  (state: AppState) =>
    state?.Config?.[key]?.kioskUISettings?.miscellaneous?.showPopular;

export const isAddOnEnabled =
  ({ key }) =>
  (state: AppState) =>
    state?.Config?.[key]?.kioskUISettings?.miscellaneous?.showAddons;

export const getParcelCharge = (state: AppState) =>
  state?.Config?.[sessionStorage.getItem("location")]?.parcelCharge;

export const selectTaxDetails = (state: AppState) =>
  state?.Config?.['default']?.taxDetails;

export const selectReceiptTemplates = (state: AppState) =>
  state?.Config?.[sessionStorage.getItem("location")]?.receiptTemplates;

export const isOfflinePaymnetCalculation = (state: AppState) =>
state?.Config?.[sessionStorage.getItem("location")]?.kioskUISettings?.miscellaneous?.offlineTaxCalculation;

export const getPrinterConfig = (state: AppState) =>
  state?.Config?.[sessionStorage.getItem("location")];

export const selectPrinterStatus = (state: AppState) =>
  state?.Config?.[sessionStorage.getItem("location")]?.printerStatus;

export const isBrowserPrintingEnable = (state: AppState) =>
  state?.Config?.[sessionStorage.getItem("location")]?.kioskUISettings
    ?.miscellaneous?.browserPrinting;

export const getPrintConfig = (state: AppState) =>
  state?.Config?.[sessionStorage.getItem("location")]?.printerData;

export const getWallpaperConfig = (state: AppState) =>
  state?.Config?.[sessionStorage.getItem("location")]?.wallpaperConfig;

export const getIsGoHomeAllowed = (state: AppState) =>
  state?.Config?.[sessionStorage.getItem("location")]?.isGoHomeAllowed

export const getProductPricingData = (state: AppState) =>
  state?.Config?.[sessionStorage.getItem("location")]?.productPricing
